@import '../settings/_colors.scss';

.article-one {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    max-width: 1200px;
    margin: auto;
    background-color: $color-white;
    border-radius: 1rem;
    padding: 1rem;
}

.article-one-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Centers images horizontally */
    align-items: center; /* Centers images vertically, if they have different heights */
    gap: 10px; /* Optional: Adds space between images */
}
.article-one-list img {
    max-width: 100px; /* Adjust the size as needed */
    height: auto;
    border-radius: 0.5rem; /* Optional: Adds rounded corners */
}

.article-one-third {
    border-radius: 1rem;

}



.article-two-third {
    border-radius: 1rem;

}

.article-content { 
    margin-top: 1rem;
    position: relative;
    width: 100%;
    min-height: 20rem;
}

.article-content img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; 
    padding: 1rem;
} 

