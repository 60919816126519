@import '../settings/_colors.scss';

.topmenunavigation {
  grid-column-start: 1;
  grid-column-end: 13;
  display: grid;
  grid-template-columns: 1fr auto auto;
  padding: 1rem;
  background-color: rgba(255, 255, 255, 0.965);
  width: 100%;
  max-width: 1200px;
  z-index: 1000;
  border-radius: 0 0 1rem 1rem;
  

  @media screen and (max-width: 749px) {
    position: fixed;
    top: 0;
  }
}

.topmenunavigation nav {
  grid-row-start: 2;
  grid-column-start: 1;
  grid-column-end: 4;
  justify-self: center;
  width: 100%;
  z-index: 1000;
}

.topmenunavigation-logo {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  top: 0;
  align-self: center;
  z-index: 1000;

  .desktop-logo {
    display: block;
  }

  .mobile-logo {
    display: none;
    max-height: 50px; /* Ensure the mobile logo has a maximum height of 70px */
  }

  @media screen and (max-width: 999px) {
    .desktop-logo {
      display: none;
    }

    .mobile-logo {
      display: block;
    }
  }
}

.topmenunavigation-contact {
  display: flex;
  align-items: center;

  @media screen and (max-width: 999px) {
    grid-column-start: 3;
    grid-column-end: 4;
    justify-self: end;
  }
}

.topmenunavigation-menu {
  max-width: 100%;
  justify-content: center;
}

.topmenunavigation-menu-item {
  padding: 1rem;
}

.topmenunavigation-menu-item:hover {
  text-decoration: underline;
}

.topmenunavigation-hamburger {
  grid-column-start: 2;
  grid-column-end: 3;
  position: sticky;
  top: 0;
  padding: 1rem;
  background-color: $color-white;
  z-index: 1000;
}

.hamburger {
  grid-column-start: 2;
  grid-column-end: 3;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-self: end;
  align-self: flex-end;
  justify-content: space-between;
  height: 24px;
  width: 30px;
  padding: 0;
  margin: 0;
  z-index: 1000;

  .hamburger-line {
    width: 40px;
    height: 4px;
    background-color: $color-text-primary;
    border-radius: 1rem;
  }
}

/* Media Queries */
@media screen and (max-width: 999px) {
  .topmenunavigation-menu {
    display: block;
    width: 100%;
    z-index: 999;
  }

  .topmenunavigation-contact {
    display: flex;
    justify-content: flex-end;
    grid-row-start: 1;
  }

  .hidden {
    display: none;
  }
}

@media screen and (min-width: 1000px) {
  .topmenunavigation-menu {
    display: flex;
    z-index: 1000;
  }

  .hamburger {
    display: none;
  }

  .topmenunavigation-contact {
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 1;
    justify-self: end;
    align-self: center;
    top: 0;
    background-color: $color-white;
    z-index: 1000;
    height: 100%;
  }
}
