footer {
    grid-column-start: 1;
    grid-column-end: -1;
    margin-bottom: 0;
    background-color: $color-container-bg-dark;
    color: $color-white;
    padding: 20px 0;
    text-align: center;
    font-size: 1rem;
    font-weight: 300;
    margin-top: 20px;
    justify-items: center;
    a {
        color: $color-white;
        text-decoration: none;
        &:hover {
        text-decoration: underline;
        }
    }
}

.footer-layout {
    grid-column-start: 1;
    grid-column-end: -1;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    min-height: 25rem;
}