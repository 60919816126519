.loader-container {
    display: flex;
    justify-content: center;
    align-items: baseline;
    height: 100vh;
    grid-column-start: 1;
    grid-column-end: 13;
  }

  // src/app_styles/css/components/ScrollToTopButton.scss

.scroll-to-top {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  z-index: 1000;
}

.scroll-to-top-button {
  background-color: $color-primary;
  border: none;
  border-radius: 50%;
  padding: 1rem;
  cursor: pointer;
  transition: opacity 0.3s;
  opacity: 0.8;

  &:hover {
      opacity: 1;
  }

  svg {
      color: white;
      width: 1.5rem;
      height: 1.5rem;
  }
}
