// src/app_styles/css/settings/_colors.scss

// Primary colors
$color-primary: #003082;
$color-primary-light: #3366cc;
$color-primary-dark: #000033;

// Secondary colors
$color-secondary: #60605B;
$color-secondary-light: #a5a5a5;
$color-secondary-dark: #303030;

// Neutral colors
$color-white: #fff;
$color-black: #000;
$color-gray-light: #BFBAAF;
$color-gray-dark: #707070;
$color-red-light: #b00000;

// Background colors
$color-bg: #003082;
$color-bg-light: #f4f4f4;
$color-bg-dark: #f4f4f4;;

// Container background colors
$color-container-bg: #003082;
$color-container-bg-light: #f4f4f4;
$color-container-bg-dark: #2c2c2c;

// Text colors
$color-text-primary: $color-black;
$color-text-secondary: $color-secondary;
$color-text-muted: $color-gray-dark;

// Border colors
$color-border-light: $color-gray-light;
$color-border-dark: $color-gray-dark;

// Button colors
$color-btn-text: $color-white;
$color-btn-text-light: $color-white;
$color-btn-text-dark: $color-white;
$color-btn-bg: $color-primary;
$color-btn-bg-light: $color-primary;
$color-btn-bg-dark: $color-primary;