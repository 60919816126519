@import '../settings/_colors.scss';

.carrierpage-jobs {
    background-color: $color-white;
    border-radius: 1rem;
    padding: 2rem;
}

.carrierpage-jobs-menu  {
    grid-column-start: 1;
    background-color: $color-bg-light;
    border-radius: 1rem;
    padding: 20px;
    box-shadow: 2px 0 5px rgba(0,0,0,0.1);
    margin: 1rem;
}

.carrierpage-jobs-menu-btn {
    width: 100%;
    padding: 15px;
    text-align: left;
    border: none;
    background: none;
    outline: none;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
    color: $color-text-primary;
}

.carrierpage-jobs-menu-btn:hover {
    background-color: $color-primary;
    color: $color-white;
}

.carrierpage-jobs-content {
    grid-column-start: span 1;
    padding: 20px;
    border-radius: 1rem;
    background-color: $color-bg-light;
}

.carrierpage-jobs-content-section h2 {
    display: block;
    padding: 1rem;
}

.carrierpage-jobs-content-section p {
    margin-top: 0;
    padding: 1rem;
}

.carrierpage-jobs-content-section ul {
    list-style-type: disc;
    padding-left: 2rem;
}
