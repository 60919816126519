.marketing-page-form {
    margin-top: 3rem;
    max-width: 1000px;
}

.marketing-page-form-box {
    border: 1px solid #ccc;
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding: 1rem;
}



.marketing-page-box2 {
    margin-left: 3rem;
}

.marketing-page-form-box div {
    padding-top: 1rem;
}

.marketing-page-form-box div input {
    margin-left: 1rem ;
    margin-right: 0.2rem;
    cursor: pointer;
    scale: 1.5;
    margin-top: 1.2rem;
}

.form-input-radio-field-disabled {
    background-color: #ccc;
    cursor: not-allowed;
}