h1 {
    padding: 0.5rem 0.5rem;
}

h2 {
    padding: 0.5rem 0.5rem;
}

h3 {
    padding: 0.5rem 0.5rem;
}

h4 {
    padding: 0.5rem 0.5rem;
} 

h5 {
    padding: 0.5rem 0.5rem;
}

p {
    padding: 0.2rem 0.5rem;
}     