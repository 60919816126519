// src/app_styles/css/components/ContactPage.scss


@import '../settings/_colors.scss';

.contactpage-info {
    background-color: $color-white;
    border-radius: 1rem;
    padding: 2rem;
}
.contactpage-info p {
    padding: 0.2rem 0.5rem;
}
.contactpage-info h3 {
    padding: 0.5rem 0.5rem;

}

.contactpage-info-map{
    position: relative;
    overflow: visible;
}

.contactpage-team {
    background-color: $color-white;
    border-radius: 1rem;
    padding: 2rem;
}

.contactpage-team-dep h4 {
    grid-column-start: 1;
    grid-column-end: -1;
}

.contactpage-team-item {
    background-color: $color-bg-light;
    border-radius: 1rem;
    padding: 0.5rem;
    margin: 0.5rem 0.5rem;
    border: 1px solid $color-border-light;
}

.contactpage-team-item-info {
    padding: 0.5rem;
    text-align: center;

}

.contactpage-team-item img {
    width: 100%;
    border-radius: 1rem;
}  

