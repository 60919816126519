// File: css/components/ColorConfigurator.scss

.colorconfigurator {
    display: grid;
    grid-template-rows: auto auto 1fr auto;
    justify-items: center; /* Center items horizontally */
    align-items: center;   /* Center items vertically */
    width: 100%;/* Ensure the container takes at least full viewport height */

  }
  
  .colorconfigurator-main {
    display: grid;
    justify-items: center; /* Center items horizontally */
    align-items: center;   /* Center items vertically */
    width: 100%;
    overflow-x: hidden; /* Prevent scrolling */
    touch-action: none;
  }
  
  .colorconfigurator-image-container {
    text-align: center;
    position: relative;
    width: 100%;
    min-height: 15rem;
    overflow: hidden;

  
    img {
      width: 100%;
      max-width: 600px;
      transform: scale(1.15);
      margin: auto;
      overflow: scroll;
    }
  
    .loading-indicator {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 1.5rem;
      color: #000;
    }
  }
  
  .colorconfigurator-button {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }
  
  .colorconfigurator-swipe-area {
    width: 100%;
    max-width: 500px;
    height: 50px;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px dashed #ccc;
    cursor: grab;
    user-select: none;
    overflow-x: hidden; /* Prevent scrolling */
    touch-action: none; /* Prevent scrolling */
  }
  
  .colorconfigurator-color-options {
    display: flex;
    justify-content: center;
    flex-wrap: wrap; /* Allows wrapping */
    margin-top: 20px;
  
    button {
      background: none;
      border: none;
      cursor: pointer;
  
      img {
        width: 50px;
        height: 50px;
      }
  
      &.selected {
        border: 2px solid blue;
      }
    }
  }
  
  // Existing styles
  .model-baner {
    width: 100%;
    height: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 1rem;
    position: relative;
    @media screen and (max-width: 749px) {
      
    }

  }

  .model-baner-i5 {
    width: 100%;
    height: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 1rem;
    position: relative;
    @media screen and (max-width: 749px) {
      
    }

  }

  .model-baner-i30 {
    width: 100%;
    height: 450px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 1rem;
    position: relative;
    @media screen and (max-width: 749px) {
      
    }
  
  }
  
  .model-baner-staria {
    width: 100%;
    height: 550px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 1rem;
    position: relative;
    @media screen and (max-width: 600px) {
      height: 350px;
    }
    @media screen and (min-width: 950px) {
      height: 650px;
    }
  

  }

  .model-baner-santafe {
    width: 100%;
    height: 450px;
    display: flex;
    top: 0px;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 1rem;
    position: relative;
    @media screen and (max-width: 749px) {
      height: 300px;
      bottom: 0px;
    }
    @media screen and (max-width: 450px) {
      height: 300px;
      bottom: 0px;
    }

  }



  .model-baner img {
    width: 100%;
    height: auto;
    object-fit: cover;
    transform: scale(2);
    position: absolute;
    bottom: -50px;
    @media screen and (max-width: 749px) {
      object-fit: contain;
      transform: scale(1);
      
    }
  }

  .model-baner-i5 img {
    width: 100%;
    height: auto;
    object-fit: cover;
    transform: scale(1);
    position: absolute;
    bottom: -200px;
    @media screen and (max-width: 1000px) {
      object-fit: contain;
      transform: scale(1);
      position: static;
      
    }
  }

  .model-baner-i30 img {
    width: 100%;
    height: auto;
    object-fit: cover;
    transform: scale(1);
    position: absolute;
    bottom: -200px;
    @media screen and (max-width: 1000px) {
      object-fit: contain;
      transform: scale(1);
      position: static;
      
    }
  }

  .model-baner-staria img {
    width: 100%;
    height: auto;
    object-fit: cover;
    transform: scale(1);
    position: absolute;
    top: 0px;
    @media screen and (max-width: 749px) {
      object-fit: contain;
      transform: scale(1);
      
    }
    @media screen and (min-width: 1050px) {
      object-fit: contain;
      transform: scale(1);
      top: -50px;
      
    }
  }

  .model-baner-santafe img {
    width: 100%;
    height: auto;
    object-fit: cover;
    transform: scale(1);
    position: absolute;
    scale: 1.2;
    top: -100px;
    @media screen and (max-width: 749px) {
      object-fit: contain;
      transform: scale(1);
      
    }
    @media screen and (min-width: 1050px) {
      object-fit: contain;
      transform: scale(1);
      top: -50px;
      
    }
  }

  .model-baner h1 {
    position: absolute;
    top: 0;
    left: 0;
    right: 50%;
    background-color: $color-bg;
    border-radius: 0 0 1rem 0;
    color: white;
    padding: 1rem;
    text-align: center;
    @media screen and (max-width: 749px) {
      right: 0%;
    }
  }

  .model-baner h2 {
    position: absolute;
    bottom: 0;
    left: 50%;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 1rem 0 0 0;
    padding: 1rem;
    text-align: center;
    color: white;
    @media screen and (max-width: 749px) {
      display: none;
    }
  }

  .model-baner-i5 h1 {
    position: absolute;
    top: 0;
    left: 0;
    right: 80%;
    background-color: $color-bg;
    border-radius: 0 0 1rem 0;
    color: white;
    padding: 1rem;
    text-align: center;
    @media screen and (max-width: 1000px) {
      right: 0;
      left: 0;
    }
  }

  .model-baner-i30 h1 {
    position: absolute;
    top: 0;
    left: 0;
    right: 80%;
    background-color: $color-bg;
    border-radius: 0 0 1rem 0;
    color: white;
    padding: 1rem;
    text-align: center;
    @media screen and (max-width: 1000px) {
      right: 0;
      left: 0;
    }
  }

  .model-baner-staria h1 {
    position: absolute;
    top: 0;
    left: 0;
    right: 0%;
    background-color: $color-bg;
    border-radius: 0 0 1rem 0;
    color: white;
    padding: 1rem;
    text-align: center;
    @media screen and (max-width: 1000px) {
      right: 0;
      left: 0;
    }
  }

  .model-baner-santafe h1 {
    position: absolute;
    top: 0;
    left: 0;
    right: 0%;
    background-color: $color-bg;
    border-radius: 0 0 1rem 0;
    color: white;
    padding: 1rem;
    text-align: center;
    @media screen and (max-width: 1000px) {
      right: 0;
      left: 0;
    }
  }

  .model-baner-i5 h2 {
    position: absolute;

    bottom: 0;
    left: 0;
    right: 50%;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 1rem 0 0 0;
    padding: 1rem;
    text-align: center;
    color: white;
    @media screen and (max-width: 1000px) {
      display: none;
    }
  }

  .model-baner-i30 h2 {
    position: absolute;

    bottom: 0;
    left: 0;
    right: 50%;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 1rem 0 0 0;
    padding: 1rem;
    text-align: center;
    color: white;
    @media screen and (max-width: 1000px) {
      display: none;
    }
  }

  .model-baner-staria h2 {
    position: absolute;

    bottom: 0;
    left: 0;
    right: 50%;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 1rem 0 0 0;
    padding: 1rem;
    text-align: center;
    color: white;
    @media screen and (max-width: 1000px) {
      display: none;
    }
  }

  .model-baner-santafe h2 {
    position: absolute;

    bottom: 0;
    left: 0;
    right: 50%;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 1rem 0 0 0;
    padding: 1rem;
    text-align: center;
    color: white;
    @media screen and (max-width: 1000px) {
      display: none;
    }
  }

  .SantaFe-img img {
    width: 100%;
  }

  .model-configuration {}

  .model-configuration h2 {
    color: $color-primary;
    margin-top: 1rem;
  }

  .model-price {
    color: red;
    font-weight: bold;
  }
  
  .model-image {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 1rem;
  }
  
  .model-image img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
  
  .article-content img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  
  .model-photos {
    grid-column-start: 1;
    grid-column-end: -1;
    border-radius: 1rem;
  }

  .price-list {
    justify-self: center;
    padding: 2rem;
    h2 {
        margin-bottom: 1rem;
    }
    ul {
        list-style-type: none;
        padding: 0;
        li {
            margin-bottom: 0.5rem;
            padding: 1rem;
            .file-item {
                display: flex;
                align-items: center;
                .file-icon {
                    margin-right: 3.5rem;
                    transform: scale(3);
                }
                .file-name {
                    color: #000;
                    text-decoration: none;
                    font-size: larger;
                }
            }
            a {
                text-decoration: none;
                &:hover .file-name {
                    text-decoration: underline;
                }
            }
        }
    }
}

/* =============== FINANCE ================= */

.finance {
  display: flex;
  padding: 2rem;
  flex-direction: column;
  gap: 1rem 1rem;
  align-items: baseline;
  margin-top: 1rem;
  justify-content: space-between;
  height: 20rem;
  @media screen and (max-width: 749px) {
    height: auto;
    padding: 0.1rem;
    max-width: 100%;
  }
}

.finance div {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  margin: 0.1rem;
  @media screen and (max-width: 749px) {
    flex-direction: column;
    gap: 1rem;
  }
}

.finance-downpayment span, .finance-monthlypayment span, .finance-period span  {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  background-color: rgb(193, 23, 23);
  color: white;
  border-radius: 2rem;
  padding: 1rem;
  transition: 0.5s;
  @media screen and (max-width: 749px) {
    width: max-content;
    text-align: center;
  }
  &:hover {
    transform: scale(1.02);
    transition: 0.5s;
  }
}


.model-i5 {
  padding: 1rem;
}
.table-container {
  overflow-x: auto;
}
.model-table-i5 {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 18px;
  text-align: left;
}
.model-table-i5 thead th {
  background-color: $color-primary; /* Zmieniony na konkretny kolor, zamiast zmiennej SCSS */
  color: white;
  padding: 12px;
  min-width: 150px;
  border: 1px solid #ddd;
}

.model-table-i5 tbody tr {
  transition: 0.3s;
}
.model-table-i5 tbody tr:hover {
  transition: 0.3s;
  transform: scale(1.02);
}

.model-table-i5 tbody td {
  padding: 12px;
  border: 1px solid #ddd;
}
.model-table-i5 tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}
.model-table-i5 tbody tr:hover {
  background-color: #f1f1f1;
}
.model-table-i5 .align-right {
  text-align: right;
}

.special-price {
  color: red;
  font-weight: bolder;
  font-size: 1.3rem;
}