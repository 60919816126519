// src/app_styles/css/settings/_fonts.scss

@font-face {
    font-family: 'HyundaiSansHeadOffice';
    src: url('../../fonts/HyundaiSansHeadOffice-Bold.eot');
    src: url('../../fonts/HyundaiSansHeadOffice-Bold.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/HyundaiSansHeadOffice-Bold.woff') format('woff'),
         url('../../fonts/HyundaiSansHeadOffice-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'HyundaiSansHeadOffice';
    src: url('../../fonts/HyundaiSansHeadOffice-Light.eot');
    src: url('../../fonts/HyundaiSansHeadOffice-Light.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/HyundaiSansHeadOffice-Light.woff') format('woff'),
         url('../../fonts/HyundaiSansHeadOffice-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'HyundaiSansHeadOffice';
    src: url('../../fonts/HyundaiSansHeadOffice-Medium.eot');
    src: url('../../fonts/HyundaiSansHeadOffice-Medium.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/HyundaiSansHeadOffice-Medium.woff') format('woff'),
         url('../../fonts/HyundaiSansHeadOffice-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'HyundaiSansHeadOffice';
    src: url('../../fonts/HyundaiSansHeadOffice-Regular.eot');
    src: url('../../fonts/HyundaiSansHeadOffice-Regular.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/HyundaiSansHeadOffice-Regular.woff') format('woff'),
         url('../../fonts/HyundaiSansHeadOffice-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'HyundaiSansTextOffice';
    src: url('../../fonts/HyundaiSansTextOffice-Bold.eot');
    src: url('../../fonts/HyundaiSansTextOffice-Bold.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/HyundaiSansTextOffice-Bold.woff') format('woff'),
         url('../../fonts/HyundaiSansTextOffice-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'HyundaiSansTextOffice';
    src: url('../../fonts/HyundaiSansTextOffice-BoldItalic.eot');
    src: url('../../fonts/HyundaiSansTextOffice-BoldItalic.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/HyundaiSansTextOffice-BoldItalic.woff') format('woff'),
         url('../../fonts/HyundaiSansTextOffice-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'HyundaiSansTextOffice';
    src: url('../../fonts/HyundaiSansTextOffice-Italic.eot');
    src: url('../../fonts/HyundaiSansTextOffice-Italic.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/HyundaiSansTextOffice-Italic.woff') format('woff'),
         url('../../fonts/HyundaiSansTextOffice-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'HyundaiSansTextOffice';
    src: url('../../fonts/HyundaiSansTextOffice-Medium.eot');
    src: url('../../fonts/HyundaiSansTextOffice-Medium.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/HyundaiSansTextOffice-Medium.woff') format('woff'),
         url('../../fonts/HyundaiSansTextOffice-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'HyundaiSansTextOffice';
    src: url('../../fonts/HyundaiSansTextOffice-MediumItalic.eot');
    src: url('../../fonts/HyundaiSansTextOffice-MediumItalic.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/HyundaiSansTextOffice-MediumItalic.woff') format('woff'),
         url('../../fonts/HyundaiSansTextOffice-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'HyundaiSansTextOffice';
    src: url('../../fonts/HyundaiSansTextOffice-Regular.eot');
    src: url('../../fonts/HyundaiSansTextOffice-Regular.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/HyundaiSansTextOffice-Regular.woff') format('woff'),
         url('../../fonts/HyundaiSansTextOffice-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  body {
    font-family: 'HyundaiSansHeadOffice', sans-serif;
  }


  