button {
  background-color: $color-btn-bg;
  color: $color-btn-text;
  padding: 1rem;
  border: none;
  border-radius: 1rem;
  cursor: pointer;
  padding: 0.8rem 1rem;
  font-size: 1rem;
  font-weight: bold;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

button:hover {
  background-color: darken($color: $color-primary, $amount: 10%);
  transform: scale(1.03);
  transition: 0.3s;
}

.actionbutton {
    border-radius: 1rem;
    border: none;
    background-color: $color-btn-bg;
    color: $color-btn-text;
    padding: 0.8rem 1rem;
    font-size: 1.2rem;
    font-weight: bold;
    margin: 0.5rem 0;
    cursor: pointer;
  }
  
  .actionbutton:hover {
    background-color: $color-btn-bg-light;
    transition: 0.3s;
  }