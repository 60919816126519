.cookie-consent-banner {
    background: white;
    padding: 1rem;
    font-size: 1rem;
    color: black;
  }
  
  .cookie-consent-button {
    color: white;
    font-size: 0.8rem;
    padding: 0.5rem 1rem;
    border: none;
    cursor: pointer;
    background-color: #0033A0; /* Granatowy kolor */
    margin-right: 1rem;
  
    &:hover {
      background-color: #002280; /* Ciemniejszy odcień granatowego */
    }
  }
  
  .cookie-consent-decline-button {
    color: white;
    font-size: 0.8rem;
    padding: 0.5rem 1rem;
    border: none;
    cursor: pointer;
    background-color: #C8102E; /* Czerwony kolor */
    margin-right: 1rem;
  
    &:hover {
      background-color: #A00C24; /* Ciemniejszy odcień czerwonego */
    }
  }
  