.carousel {
    grid-column-start: 1;
    grid-column-end: -1;
    position: relative;
    width: 100%;
    overflow: hidden;
    border-radius: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.carousel-track-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    overflow: hidden;
}

.carousel-track {
    display: flex;
    transition: transform 0.5s ease-in-out;
}

.carousel-slide {
    min-width: 100%; /* Full width slide */
    list-style: none;
    position: relative;
    box-sizing: border-box;
}

.carousel-slide img {
    width: 100%;
    object-fit: cover;
    border-radius: 1rem;
}

.text-container {
    position: absolute;
}

.left-side {
    left: 10%;
}

.right-side {
    right: 10%;
}

.top-side {
    top: 10%;
}

.bottom-side {
    bottom: 10%;
}

.white {
    color: white;
}

.black {
    color: black;
}

.button-container {
    margin-top: 10px;
}

.carousel-nav {
    position: absolute;
    bottom: 0px; /* Adjusted to place below the image */
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
}

.carousel-indicator {
    background-color: #ccc;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    margin: 0 2px; /* Smaller margin */
    width: 1px; /* Smaller size */
    height: 1px; /* Smaller size */
    padding: 0.5rem;
}

.carousel-indicator.current-slide {
    background-color: #0c13d972;
}

@media screen and (max-width: 999px) {
    .carousel-slide {
        min-width: 100%; /* Full width slide */
    }
    
    .carousel-track {
        padding: 0;
    }
}
