// src/app_styles/css/layout/_grid.scss
body, html {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.grid-mega {
  display: grid;
  grid-template-columns: auto repeat(10, 1fr) auto;
  grid-template-rows: auto minmax(30vh, auto) auto;
  margin: auto;
  justify-items: center;
  overflow-x: hidden;
  @media screen and (max-width: 749px) {
    margin-top: 7rem;
  }
}

.grid {
  display: grid;
  grid-column-start: 1;
  grid-column-end: -1;
  grid-template-columns: repeat(12, 1fr);
  margin: auto;
  justify-content: center;
}


.grid-page {
  grid-column-start: 1;
  grid-column-end: 13;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  background-color: $color-bg-light;
  border-radius: 1rem;
  max-width: 1200px;
  width: 100%;

  @media screen and (max-width: 749px) {
  }
  
  @media screen and (min-width: 750px) and (max-width: 999px) {
    padding: 1rem;
    margin: 0 1.3rem;
  }
  @media screen and (min-width: 1000px)  {
    padding: 1rem;
    margin: 0 1.3rem;
  }


}

.grid-color-white {
  background-color: $color-white;
  border-radius: 1rem;
  margin: 0 0.5rem;
  transform: scale(1);
  transition: 1s;
}


/* ====== Grid 1 ====== */

.grid-page-1 {
  grid-column-start: 1;
  grid-column-end: -1;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  max-width: 1200px;
  border-radius: 1rem;


  @media screen and (max-width: 749px) {
    grid-template-columns: 1fr;
  }
  @media screen and (min-width: 750px) and (max-width: 999px) {
    grid-template-columns: 1fr;
  }
  @media screen and (min-width: 1000px)  {
    grid-template-columns: 1fr;
  }

}

.grid-page-1-sub {
  grid-column-start: span 1;
  padding: 1.2rem;

  @media screen and (max-width: 749px) {
    grid-column-start: span 1;
  }

  @media screen and (min-width: 750px) and (max-width: 999px) {
    padding: 0.8rem;
    grid-column-start: span 1;
  }

  @media screen and (min-width: 1000px)  {
    padding: 0.8rem;
    grid-column-start: span 1;
  }
}

/* ====== Grid 2 ====== */

.grid-page-2 {
  grid-column-start: 1;
  grid-column-end: -1;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  max-width: 1200px;

  @media screen and (max-width: 749px) {
    grid-template-columns: 1fr;
  }

  @media screen and (min-width: 750px) and (max-width: 999px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (min-width: 1000px)  {
    grid-template-columns: 1fr 1fr;
  } 

}




/* ====== Grid 3 ====== */

.grid-page-3 {
  grid-column-start: 1;
  grid-column-end: -1;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  max-width: 1200px;

  
  @media screen and (max-width: 749px) {
    grid-template-columns: 1fr;
  }
  
  @media screen and (min-width: 750px) and (max-width: 999px) {
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (min-width: 1000px)  {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.grid-page-3-sub {
  grid-column-start: span 1;
  padding: 1.2rem;

  @media screen and (max-width: 749px) {
    grid-column-start: span 1;
  }

  @media screen and (min-width: 750px) and (max-width: 999px) {
    grid-column-start: span 1;
  }

  @media screen and (min-width: 1000px)  {
    padding: 0.8rem;
    grid-column-start: span 1;
  }
}

/* ====== Grid 4 ====== */

.grid-page-4 {
  grid-column-start: 1;
  grid-column-end: -1;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  max-width: 1200px;

  @media screen and (max-width: 749px) {
    grid-template-columns: 1fr;
  }

  @media screen and (min-width: 750px) and (max-width: 999px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (min-width: 1000px)  {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.grid-page-4-sub {
  grid-column-start: span 1;
  padding: 1.2rem;
  

  @media screen and (max-width: 749px) {
    grid-column-start: span 1;
  }

  @media screen and (min-width: 750px) and (max-width: 999px) {
    grid-column-start: span 1;
  }

  @media screen and (min-width: 1000px)  {
    padding: 0.8rem;
    grid-column-start: span 1;
  }
}

/* ====== Grid 1/3 ====== */

.grid-page-1-3 {
  grid-column-start: 1;
  grid-column-end: -1;
  display: grid;
  grid-template-columns: 1fr;
  max-width: 1200px;

  @media screen and (max-width: 749px) {
    grid-template-columns: 1fr;
  }
  @media screen and (min-width: 750px) and (max-width: 999px) {
    grid-template-columns: 1fr 2fr;
  }
  @media screen and (min-width: 1000px)  {
    grid-template-columns: 1fr 2fr;
  }

}

.grid-page-1-3-sub {
  grid-column-start: span 1;


  @media screen and (max-width: 749px) {
    grid-column-start: span 1;
  }

  @media screen and (min-width: 750px) and (max-width: 999px) {
    grid-column-start: span 1;
      padding: 0.8rem;
  }

  @media screen and (min-width: 1000px)  {
    padding: 0.8rem;
    grid-column-start: span 1;
  }
}

/* ====== Grid 2/3 ====== */

.grid-page-2-3 {
  grid-column-start: 1;
  grid-column-end: -1;
  display: grid;
  grid-template-columns: 1fr;
  max-width: 1200px;

  @media screen and (max-width: 749px) {
    grid-template-columns: 1fr;
  }
  @media screen and (min-width: 750px) and (max-width: 999px) {
    grid-template-columns: 2fr 1fr;
  }
  @media screen and (min-width: 1000px)  {
    grid-template-columns: 2fr 1fr;
  }

  .grid-page-2-3-sub {
    grid-column-start: span 1;
  
    @media screen and (max-width: 749px) {
      grid-column-start: span 1;
    }
  
    @media screen and (min-width: 750px) and (max-width: 999px) {
      grid-column-start: span 1;
      padding: 0.8rem;
    }
  
    @media screen and (min-width: 1000px)  {
      padding: 0.8rem;
      grid-column-start: span 1;
    }
  }
}

.grid-page-sub-1 {
  grid-column-start: span 1;
  padding: 1.2rem;

  @media screen and (max-width: 749px) {
    grid-column-start: span 1;
  }

  @media screen and (min-width: 750px) and (max-width: 999px) {
    padding: 0.8rem;
    grid-column-start: span 1;
  }

  @media screen and (min-width: 1000px)  {
    padding: 0.8rem;
    grid-column-start: span 1;
  }
}

.grid-page-sub-2 {
  grid-column-start: span 1;
  padding: 1.2rem;

  @media screen and (max-width: 749px) {
    grid-column-start: span 2;
  }

  @media screen and (min-width: 750px) and (max-width: 999px) {
    padding: 0.8rem;
    grid-column-start: span 2;
  }

  @media screen and (min-width: 1000px)  {
    padding: 0.8rem;
    grid-column-start: span 2;
  }
}

.grid-page-sub-3 {
  grid-column-start: span 1;
  padding: 1.2rem;

  @media screen and (max-width: 749px) {
    grid-column-start: span 2;
  }

  @media screen and (min-width: 750px) and (max-width: 999px) {
    padding: 0.8rem;
    grid-column-start: span 3;
  }

  @media screen and (min-width: 1000px)  {
    padding: 0.8rem;
    grid-column-start: span 3;
  }
}

.grid-page-sub-4 {
  grid-column-start: span 1;
  padding: 1.2rem;

  @media screen and (max-width: 749px) {
    grid-column-start: span 2;
  }

  @media screen and (min-width: 750px) and (max-width: 999px) {
    padding: 0.8rem;
    grid-column-start: span 3;
  }

  @media screen and (min-width: 1000px)  {
    padding: 0.8rem;
    grid-column-start: span 4;
  }
}