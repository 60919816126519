form {
  grid-column-start: 1;
  grid-column-end: 13;
  border-radius: 1rem;
  background-color: #fff;
  padding: 1rem;
  transform: scale(1);
  transition: 1s;
  
}

form:hover {
  transform: scale(1.01);
  transition: 1s;
}

.form-step {
}

.form-group {
  grid-column-start: 1;
  grid-column-end: 13;
  display: grid;
}

.formheader {
  grid-column-start: 1;
  grid-column-end: 13;
  padding: 0.5rem 0rem;
}

.formheader-group {
  grid-column-start: 1;
  grid-column-end: -1;
  padding: 0.8rem 0.5rem;
}



// ====== FORM TEXTAREA ======

.form-input-textarea {
  display: flex;
  flex-direction: column;
}

.form-input-textarea-label {
}

.form-input-textarea-field {
  height: 10rem;
  font-family: sans-serif;
  border-radius: 1rem;
  border: 1px solid #ccc;
  padding: 0.5rem 0.5rem;
}


// ====== TEXT / NUMBER / DATE ======

.form-input-text {
  display: flex;
  flex-direction: column;
}

.form-input-text-label {
  font-weight: 700;
}

.form-input-text-field, .form-input-text-input[type="date"] {
  width: 100%;
  height: 3rem;
  padding: 0.5rem;
  border-radius: 1rem;
  border: 1px solid #ccc;
}

// ====== CHECKBOX ======

.form-input-checkbox {
  border: 1px solid #ccc;
  border-radius: 1rem;
  justify-content: center;
  margin: 0.5rem;
}

.form-input-checkbox-label {
  cursor: pointer;
  font-weight: 700;
  margin-left: 1rem;
}


.form-input-checkbox-field {
  cursor: pointer;
}

.form-input-checkbox-field:checked ~ .form-input-checkbox {
  background-color: rgb(27, 7, 104);

}


// ====== ENUM ======

.form-input-enum {
  display: flex;
  flex-direction: column;
}

.form-input-enum-label {
  font-weight: 700;
}

.form-input-enum-field {
  height: 3rem;
  width: 100%;
  padding: 0.5rem;
  border-radius: 1rem;
  border: 1px solid #ccc;
}

/* ====== RADIO ====== */

.form-input-radio {
  flex-wrap: wrap;
  gap: 10px;
}

.form-input-radio-label {
  width: 8rem;
  font-weight: 700;
  align-self: center;
  height: 100%;
}

.form-input-radio-field {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: left;
  gap: 10px;
  padding: 0.2rem 0.2rem;
}

.form-input-radio-field-option {
  
  display: flex;
  flex-direction: row;
  cursor: pointer;
  min-width: 2rem;
  border: 1px solid #ccc;
  border-radius: 1rem;
}

.form-input-radio-field-option label {
  cursor: pointer;
  padding: 0.4rem 1rem;
  border-radius: 1rem;
}

.form-input-radio-field-option input {
  cursor: pointer;
  display: none;
  margin-left: 0.5rem;
}

.form-input-radio-field-option input[type="radio"]:checked + label {
  background-color:  rgb(27, 7, 104);
  color: $color-white;
  border: none;
}

// ====== IMAGES UPLOAD ======

.upload-container {
  display: grid;
  gap: 10px;
  padding: 1.5rem;
  justify-content: center;
  grid-column-start: 1;
  grid-column-end: -1;

  @media screen and (max-width: 749px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (min-width: 750px) and (max-width: 999px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media screen and (min-width: 1000px)  {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  } 
}

.upload-placeholder {
  width: 10rem;
  height: 10rem;
  border: 2px dashed #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  justify-self: center;
}

.upload-placeholder input[type="file"] {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.upload-placeholder::before {
  content: "+";
  font-size: 5rem;
  color: #ccc;
}

.preview-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.preview {
  grid-column: span 1;
  background-color: $color-white;
  color: black;
}

.preview img {
  max-width: 100%;
  height: auto;
}

.preview button {
  background-color: #f00;
  color: #fff;
  padding: 0.5rem;
  border: none;
  border-radius: 50%;
  position: absolute;
  top: -10px;
  right: -17px;
  min-width: 0;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}


.form-navigation {
  margin-left: 0.5rem;
}

.form {
  position: relative;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-content p {
  margin-top: 10px;
}
