// src/app_styles/css/base/_typography.scss
body {
  color: $color-text-primary;
  background-color: $color-bg-light;
  font-family: 'HyundaiSansTextOffice-Regular', Arial, sans-serif;
}

a {
  color: $color-primary;
  text-decoration: none;

  &:visited,
  &:active {
    color: $color-primary;
    text-decoration: none;
  }
}

ul {
  list-style-type: none;
  padding: 0;
}

input:invalid {
  border: 1px solid $color-red-light;
}

h1 {
  font-size: 2.5rem;
  font-weight: 700;
  margin: 0;
}

h2 {
  font-size: 2rem;
  font-weight: 700;
  margin: 0;
}

h3 {
  font-size: 1.5rem;
  font-weight: 700;
  margin: 0;
}

h4 {
  font-size: 1.25rem;
  font-weight: 700;
  margin: 0;
}

h5 {
  font-size: 1rem;
  font-weight: 700;
  margin: 0;
}

label{ 
  color: gray;
}

input {
  font-size: larger;
}