.service-hero {
    grid-column-start: 1;
    grid-column-end: -1;
    border-radius: 1rem;
    position: relative;
    height: 400px;
    overflow: hidden; /* Dodanie tej właściwości, aby ukryć nadmiar obrazka */
}

.service-hero img {
    object-fit: cover; /* Ustawienie object-fit na cover */
    position: absolute;
    top: 0;
    left: -50%; /* Przesunięcie obrazka w lewo o 25% */
    width: 150%; /* Zwiększenie szerokości obrazka o 50% */
    height: 100%;
    clip-path: inset(0 0 0 25%); /* Przycięcie 25% z lewej strony */
    border-radius: 1rem;
}

.service-hero h1 {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 1rem;
    margin: 0;
    border-radius: 0 1rem 0 0;
}

.service-hero h2 {
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 1rem;
    margin: 0;
    border-radius: 0 1rem 0 0;
}

.accordion {
    grid-column-start: 1;
    grid-column-end: -1;
    background-color: white;
    border-radius: 1rem;
    padding: 2rem;
    .accordion-item {
        margin-bottom: 1rem;

        .accordion-button {
            background-color: $color-btn-bg;
            color: white;
            padding: 1rem;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            text-align: left;
            width: 100%;
            margin: 0;
            &:hover {
                background-color: #0056b3;
            }
        }

        .accordion-content {
            display: none;
            background-color: #f1f1f1;
            padding: 1rem;
            border-radius: 5px;
            border: 1px solid #ccc;
        }
    }
}
